<template>
  <div class="about">
    <!-- pc端 -->
    <div class="header_center" v-if="!_isMobile()">
      <div class="center1 contain"></div>
      <center-slot>
        <template #txt1> ADVANTAGE </template>
        <template #txt2> 我们的优势 </template>
      </center-slot>
      <div class="contain">
        <div class="center2">
          <ul>
            <li>
              <img src="@/assets/about/about_3.png" />
              <div class="about2">内容优质</div>
              <p class="about3">
                向知课程均由顶尖学者亲授，内容质量强保障；专业知识拆解与动画制作，促进学生有效吸收知识。
              </p>
            </li>
            <li>
              <img src="@/assets/about/about_1.png" />
              <div class="about2">体系完整</div>
              <p class="about3">
                基于“教得好”与“学得好”而设计服务系统，深耕教学教务体系；切合教师与学生的需求，做教学与学习行为引导，促进教学效果。
              </p>
            </li>
            <li>
              <img src="@/assets/about/about_2.png" />
              <div class="about2">平台功能</div>
              <p class="about3">
                基于教学路径设计平台功能，教师可以根据学情快速组合改编；严控刷课，定期发布学情报告，实现便捷化教学管理。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <MyFooter class="footer" v-if="!_isMobile()" />
    <!-- 移动端 -->
    <div class="moblie-box" v-if="_isMobile()">
      <img src="@/assets/moblie/bg_07.png" class="top-img" />
      <p>我们的优势</p>
      <div class="contain">
        <ul>
          <li>
            <img src="@/assets/about/about_3.png" />
            <div class="about2">内容优质</div>
            <p class="about3">
              向知课程均由顶尖学者亲授，内容质量强保障；专业知识拆解与动画制作，促进学生有效吸收知识。
            </p>
          </li>
          <li>
            <img src="@/assets/about/about_1.png" />
            <div class="about2">体系完整</div>
            <p class="about3">
              基于“教得好”与“学得好”而设计服务系统，深耕教学教务体系；切合教师与学生的需求，做教学与学习行为引导，促进教学效果。
            </p>
          </li>
          <li>
            <img src="@/assets/about/about_2.png" />
            <div class="about2">平台功能</div>
            <p class="about3">
              基于教学路径设计平台功能，教师可以根据学情快速组合改编；严控刷课，定期发布学情报告，实现便捷化教学管理。
            </p>
          </li>
        </ul>
      </div>
    </div>
    <MyMoblieFooter class="footer" v-if="_isMobile()" />
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped lang="less">
.moblie-box {
  background: #fff;
  .top-img {
    width: 100%;
    height: auto;
  }
  p {
    font-size: 0.4rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-bottom: 0.4rem;
  }
  .contain {
    font-family: PingFangSC-Medium, PingFang SC;
    padding: 0 0.36rem;
    ul {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      li {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 0 0.32rem;
        background: #f8f9fb;
        border-radius: 8px;
        list-style: none;
        margin-bottom: 0.32rem;
        img {
          width: 1.48rem;
          height: 1.48rem;
          background-size: contain;
          margin-top: 0.6rem;
        }
        .about2 {
          font-size: 0.36rem;
          font-weight: 500;
          color: #333333;
          margin: 0.4rem 0 0.14rem;
        }
        .about3 {
          text-align: justify;
          font-size: 0.28rem;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
.header_center {
  height: 100%;
  margin-bottom: 121px;
  .center1 {
    width: 100%;
    height: 448px;
    background: url("~@/assets/about/about_bg.png") no-repeat center / cover;
    margin-bottom: 64px;
  }
  .center2 {
    font-family: PingFangSC-Medium, PingFang SC;
    ul {
      display: flex;
      margin-top: 53px;
      li {
        width: 387px;
        height: 272px;
        background: #f8f9fb;
        border-radius: 8px;
        list-style: none;
        &:nth-child(2) {
          margin: 0 19px 0 20px;
        }
        img {
          width: 84px;
          height: 84px;
          background-size: contain;
          margin-left: 151px;
          margin-top: 33px;
        }
        .about2 {
          height: 28px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
          margin-left: 156px;
          margin-top: 23px;
        }
        .about3 {
          width: 349px;
          height: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          margin: 8px 19px 40px;
        }
      }
    }
  }
}
</style>
